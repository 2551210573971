<template>
  <v-card :loading="submitting">
    <v-card-title>
      Password Reset
    </v-card-title>
    <v-form
      v-if="!success"
      ref="form"
      class="passwordReset"
      @submit.prevent="passwordResetConfirm"
    >
      <v-card-text>
        <v-alert
          v-for="error in errorMessages"
          :key="error"
          type="error"
        >
          {{ error }}
        </v-alert>
        <p>Confirm your new password to complete the reset.</p>
        <v-text-field
          v-model="credentials.password1"
          :disabled="submitting"
          :rules="passwordRules"
          required
          type="password"
          label="Password"
        />
        <v-text-field
          v-model="credentials.password2"
          :disabled="submitting"
          :rules="passwordMatchRules"
          required
          type="password"
          label="Repeat Password"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn
          :disabled="submitting"
          type="submit"
        >
          Request Reset
        </v-btn>
      </v-card-actions>
    </v-form>
    <v-card-text v-else>
      Password reset successful. You may now login.
    </v-card-text>
  </v-card>
</template>

<script>
import {allianceBaseURL} from '@/api'
import { parseResponseErrors } from '@/utils/errors'
import { passwordRules } from '@/utils/rules'

export default {
  data() {
    return {
      credentials: {
        password1 : '',
        password2 : '',
      },
      submitting: false,
      success: null,
      errorMessages: null,
      passwordRules: passwordRules,
      passwordMatchRules: [
        v => !!v || 'Field is required',
        v => (!!v && v) === this.credentials.password1 || 'Passwords do not match'
      ],
    }
  },
  methods: {
    passwordResetConfirm: function () {
      if (!this.$refs.form.validate()) {
        return
      }
      this.submitting = true
      this.success = null
      this.errorMessages = null
      const data = {
        new_password1: this.credentials.password1,
        new_password2: this.credentials.password2,
        uid: this.$route.params.uid,
        token: this.$route.params.token,
      }
      return this.$http.post(allianceBaseURL + '/auth/password/reset/confirm/', data)
        .then((response) => {
          console.log('success!')
          console.log(response)
          this.success = true
          this.credentials = {
            password1 : '',
            password2 : '',
          }
        })
        .catch((error) => {
          this.errorMessages = parseResponseErrors(error)
          let invalidIndex = this.errorMessages.indexOf('Invalid value')
          if (invalidIndex > -1) {
            this.errorMessages[invalidIndex] = 'Invalid reset token.'
          }
          this.success = false
        })
        .finally(() => this.submitting = false)
    }
  },
}
</script>
